.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: auto;
  width: 70%;
  color: black;
}
  
.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #000;
}

.separator:not(:empty)::before {
  margin-right: .25em;
}

.separator:not(:empty)::after {
  margin-left: 1em;
}